import { unstable_defineLoader as defineLoader } from '@remix-run/node'
import { redirect, useLoaderData } from '@remix-run/react'
import { useEffect } from 'react'

import { Description } from '~/components/Description'
import { Headline } from '~/components/Headline'
import { LinkButton } from '~/components/LinkButton'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { requireAuth } from '~/services/auth/auth.server'

import { getUser } from './getUser.server'

export const loader = defineLoader(async ({ request,  }) => {
  const user = await requireAuth(request)

  if (!user.teamId)
    throw redirect('/join/team')

  const result = await getUser(request, user)

  if (!result.success) throw new Response(null, { status: 500 })
  return result.data
})

export default function ExtensionIndexRoute() {
  const user = useLoaderData<typeof loader>()
  const { CONSTANTS } = useClientEnvironmentVariables()

  useEffect(() => {
    chrome.runtime
      .sendMessage(CONSTANTS.CHROME_EXTENSION_ID, {
        type: 'login_success',
        payload: { userId: user.id, teamId: user.teamId },
      })
      .then((response) => {
        if (response.received) return
        throw new Error('Extension not found')
      })
      .catch((error) => {
        console.error(error)
        throw new Error('Extension not found')
      })
  }, [CONSTANTS.CHROME_EXTENSION_ID, user.id, user.teamId])

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <Headline>You're good to go!</Headline>
        <Description>
          Your SaaS usage activity is being shared with <b>{user.teamName}</b>.
          No further action is required. You may close this tab.
        </Description>
      </div>
    </div>
  )
}

export function ErrorBoundary() {
  const { CONSTANTS } = useClientEnvironmentVariables()

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <Headline>Please install our extension.</Headline>
        <Description>
          You must have our browser extension installed to log in to this page.
        </Description>
      </div>

      <div className="flex items-center gap-4">
        <LinkButton
          to={CONSTANTS.CHROME_EXTENSION_URL}
          target="_blank"
          icon="chrome"
          className="bg-green-600 bg-none hover:bg-green-700"
        >
          Install for Chrome
        </LinkButton>
        <LinkButton
          to={CONSTANTS.EDGE_EXTENSION_URL}
          target="_blank"
          icon="microsoftedge"
          className="bg-blue-600 bg-none hover:bg-blue-700"
        >
          Install for Edge
        </LinkButton>
      </div>
    </div>
  )
}
